export default {
	name: 'FormBody',
	props: {
		value: {
			type: Array,
			required: true,
		},
		group: {
			type: Object,
			required: false,
		},
		disabled: {
			type: Boolean,
			required: false,
		},
	},
	computed: {
		inputs: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit("input", value);
			},
		},
	},
};
