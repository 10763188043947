var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"grey lighten-2 d-flex justify-center"},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])})],1),(_vm.isLoadingData)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-container',[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',[_c('h1',{staticClass:"text-h6 primary--text",domProps:{"textContent":_vm._s(_vm.inspection && _vm.inspection.code)}}),_c('h2',{staticClass:"text-body-2 mb-0",domProps:{"textContent":_vm._s(_vm.inspection && _vm.textTransform(_vm.inspection.name))}})]),_c('v-col',{attrs:{"cols":"12","sm":"auto"}},[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.validateHeader() || _vm.isLoadingSave,"loading":_vm.isLoadingDraft},on:{"click":function($event){return _vm.send('DRAFT')}}},[_vm._v(" Guardar como borrador ")])],1)],1),(_vm.inspection)?_c('v-card',{staticClass:"my-8",attrs:{"elevation":"2"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"px-6"},[_c('validation-alert',{attrs:{"validationErrors":_vm.validationErrors || []}})],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"pb-0",attrs:{"elevation":"0","vertical":"","value":_vm.steps.current},model:{value:(_vm.steps.current),callback:function ($$v) {_vm.$set(_vm.steps, "current", $$v)},expression:"steps.current"}},[_c('v-stepper-step',{attrs:{"complete":"","step":"1","edit-icon":_vm.validateHeader()
									? 'mdi-check'
									: 'mdi-alert-circle-outline ',"editable":"","color":_vm.steps.current == 1
									? 'primary'
									: _vm.validateHeader()
									? 'success'
									: 'error'}},[_vm._v(" Inicio ")]),_c('v-stepper-content',{attrs:{"step":"1"}},[_c('form-header',{attrs:{"headers":_vm.inspection.headers}}),_c('v-btn',{staticClass:"mb-3",attrs:{"color":"primary"},on:{"click":function($event){_vm.steps.current = 2}}},[_vm._v(" Siguiente ")])],1),_vm._l((_vm.inspection.bodies),function(group,i){return [_c('v-stepper-step',{key:i + 2,attrs:{"complete":_vm.steps.views.includes(i + 2),"step":i + 2,"edit-icon":_vm.validateGroup(i)
										? 'mdi-check'
										: 'mdi-alert-circle-outline ',"editable":"","color":_vm.steps.current == i + 2
										? 'primary'
										: _vm.validateGroup(i)
										? 'success'
										: 'error'}},[_vm._v(" "+_vm._s(_vm.textTransform(group.group))+" ")]),_c('v-stepper-content',{key:i + 2 + 'vsc',attrs:{"step":i + 2}},[_c('form-body',{attrs:{"group":group},model:{value:(group.items),callback:function ($$v) {_vm.$set(group, "items", $$v)},expression:"group.items"}}),(
										_vm.steps.current < _vm.inspection.bodies.length + 1
									)?_c('v-btn',{staticClass:"mb-3 mt-10",attrs:{"color":"primary"},on:{"click":function($event){_vm.steps.current = i + 3}}},[_vm._v(" Siguiente ")]):_vm._e()],1)]})],2)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mb-12 px-6",attrs:{"elevation":"0"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center flex-wrap",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-body-2 font-weight-bold mr-5"},[_vm._v(" Valor Total: ")]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('v-alert',{staticClass:"pa-2 mr-2",attrs:{"text":"","outlined":"","color":"info"},domProps:{"textContent":_vm._s(
											'Criterios Aplicados: ' + _vm.totalItemsApply
										)}}),_vm._l((_vm.optionsAnswer),function(optionA,indexOA){return _c('v-alert',{key:'op-' + indexOA,staticClass:"pa-2 mr-2",attrs:{"text":"","outlined":"","color":optionA.color},domProps:{"textContent":_vm._s(
											_vm.getLabel(optionA.value) +
											': ' +
											optionA.total
										)}})})],2)]),_c('v-col',{staticClass:"d-flex align-center flex-wrap",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-body-2 font-weight-bold mr-5"},[_vm._v(" Porcentaje de cumplimiento: ")]),_c('v-alert',{staticClass:"pa-2",attrs:{"text":"","outlined":"","color":"info"},domProps:{"textContent":_vm._s(_vm.getPercentCompliance() + '%')}})],1),_c('hr',{staticClass:"w-100"}),_c('v-col',{staticClass:"d-flex justify-end flex-wrap",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","disabled":!_vm.validateHeader() || _vm.isLoadingSave,"loading":_vm.isLoadingDraft},on:{"click":function($event){return _vm.send('DRAFT')}}},[_vm._v(" Guardar como borrador ")]),_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"green","loading":_vm.isLoadingSave,"disabled":!_vm.completedQuestionary || _vm.isLoadingDraft},on:{"click":function($event){return _vm.send()}}},[_vm._v(" Finalizar ")])],1)],1)],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }